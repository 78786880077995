const OKSpinEidMap = {
  visit: "visit",
  enter_reg_page: "homepage",
  enter_login_page: "homepage",
  register: "EVENT_COMPLETE_REGISTRATION",//"register",注册
  login: "login",
  recharge: "EVENT_PURCHASE", //"purchase",充值
};
export default class OKSpinAnalytics {
  constructor(okSpinUserInfo) {
    if (OKSpinAnalytics.instance) {
      return OKSpinAnalytics.instance;
    }
    this.okSpinUserInfo = okSpinUserInfo;
    OKSpinAnalytics.instance = this;
  }
  SendEvent(event, data) {
    if (!OKSpinEidMap[event]) {
      return;
    }
    console.log("OKSpinAnalytics SendEvent", event, data);
    if (data.rid) {
      this.okSpinUserInfo.customerId = data.rid;
      delete data.rid;
    }
    window._okTag = window._okTag || [];
    window._okTag.push({
      eid: OKSpinEidMap[event],
      data: Object.assign({}, this.okSpinUserInfo, data),
    });
  }
  UpdateUserInfo(okSpinUserInfo) {
    this.okSpinUserInfo = okSpinUserInfo;
  }
}
OKSpinAnalytics.GetInstance = (okSpinUserInfo) => {
  if (OKSpinAnalytics.instance) {
    return OKSpinAnalytics.instance;
  }
  return new OKSpinAnalytics(okSpinUserInfo);
};
